/* --------------------- DROPDOWN --------------------- */
export const addressDdUrlV1 = 'api/v1/dropdowns/addresses/'
export const contactDdUrlV1 = 'api/v1/dropdowns/contacts/'
export const investorDdUrlV1 = 'api/v1/dropdowns/investors/'
export const investorLeadDdUrlV1 = 'api/v1/dropdowns/investor-leads/'
export const familyDdUrlV1 = 'api/v1/dropdowns/families/'
export const familyLeadDdUrlV1 = 'api/v1/dropdowns/family-leads/'
export const ambassadorDdUrlV1 = 'api/v1/dropdowns/ambassadors/'
export const vendorDdUrlV1 = 'api/v1/dropdowns/vendors/'
export const loanDdUrlV1 = 'api/v1/dropdowns/loans/'
export const trustDdUrlV1 = 'api/v1/dropdowns/trusts/'
export const userDdUrlV1 = 'api/v1/dropdowns/users/'
export const investorDropdown = 'api/v1/dropdowns/investors/';

/* --------------------- ACCOUNT --------------------- */
export const userUrls = '/api/account/user/';
export const employeesUrl = '/api/account/employees/';
export const userPositionsUrl = '/api/v1/accounts/positions/';
export const usersUrlV1 = '/api/v1/accounts/users/';
export const anonymousUserUrl = '/api/v1/accounts/sign-up/';
export const loginUser = 'api/v1/login/';
export const resetPassword = '/api/v1/accounts/forgot-password/';
export const changePassword = '/api/v1/accounts/change-password/';
export const registerUser = 'api/v1/login/';
export const verifyEmailUrlV1 = '/api/v1/accounts/verify/';

/* --------------------- ACCOUNTING --------------------- */
export const loansPaymentIntent = '/api/v1/accounting/stripe-setup-intents/';
export const stripePayment = 'api/v1/accounting/stripe-payment-method/';
export const ledgerCategoriesUrlV1 = '/api/v1/accounting/ledger-categories/';
export const bankAccountTypeUrlV1 = '/api/v1/accounting/bank-account-types/';
export const bankLedgerUrlV1 = '/api/v1/accounting/bank-ledger/';
export const bankAccountsUrlV1 = '/api/v1/accounting/banks/';
export const chargesUrlV1 = '/api/v1/accounting/charges/';
export const paymentsUrlV1 = '/api/v1/accounting/payments/';
export const masterLedgerUrlV1 = '/api/v1/accounting/master-ledger/';
export const setupIntentUrl = '/api/v1/accounting/stripe-setup-intents/';
export const stripeIntents = 'api/v1/accounting/stripe-payment-intents/';
export const stripeConfirm = '/api/v1/accounting/stripe-pay/';
export const stripePaymentAccounts = '/api/v1/accounting/stripe-payment-method/'
export const linkTokenUrl = '/api/v1/accounting/plaid/link-token/';
export const accessTokenUrl = '/api/v1/accounting/plaid/access-token/';
export const bankLedgerSyncUrl = '/api/v1/accounting/plaid/sync-transactions/';
export const loadPlaidAccountsUrl = '/api/v1/accounting/plaid/bank-accounts/';

/* --------------------- ACQUISITIONS --------------------- */
// old
export const propertiesUrl = 'api/acquisitions/acquisition/';
export const propertiesUrlDetail = 'api/acquisitions/acquisition-detail/';
export const acqShortUrl = 'api/acquisitions/acquisition-short/';
export const propertiesSub = '/api/acquisitions/substage/';
export const propertiesStatus = '/api/acquisitions/status/';
export const acqImportUrl = '/api/acquisitions/import/';
export const acqCreateScrape = 'api/acquisitions/scrape/';
export const scrapeDelete = 'api/acquisitions/mass-scrape-delete/';
export const rtpPDF = 'api/acquisitions/rtp-data/';
export const rerunScrape = 'api/acquisitions/fast-scrape/';
export const createTrustAcq = 'api/acquisitions/create-trust/';
export const massAcqDel = 'api/acquisitions/mass-acq-delete/';

// v1
export const propertiesUrlv1 = 'api/v1/acquisitions/acquisitions/';
export const propertiesDdUrlV1 = 'api/v1/dropdowns/acquisitions/'
export const acqCreateScrapev1 = 'api/v1/acquisitions/scrapes/';
export const acqSubStageUrlV1 = 'api/v1/acquisitions/acquisition-substages/';
export const acqStageUrlV1 = 'api/v1/acquisitions/acquisition-stages/';
export const acqOccupancyUrlV1 = 'api/v1/acquisitions/occupancy-status/';
export const acqImportUrlV1 = 'api/v1/acquisitions/scrape-files/';
export const acqCreateTrustUrlV1 = 'api/v1/acquisitions/create-trust/'
export const acqDisapprovedUrlV1 = 'api/v1/acquisitions/disapproved/';
export const acqMassDisapproveUrlV1 = 'api/v1/acquisitions/mass-disapprove/';

/* --------------------- ADDRESS --------------------- */
export const addressUrlV1 = 'api/v1/addresses/addresses/';
export const addressDDUrlV1 = 'api/v1/dropdowns/addresses/';

/* --------------------- APPLICATION --------------------- */
export const applicationUrl = 'api/v1/applications/applications/';
export const applicationStatusUrl = 'api/v1/applications/status/';
export const applicationContactDetailsUrl = 'api/v1/applications/applicant-details/';
export const applicationVehiclesUrl = 'api/v1/applications/vehicles/';
export const applicationEmploymentUrl = 'api/v1/applications/employment/';
export const applicationResidencesUrl = 'api/v1/applications/residences/';
export const applicationDocumentsUrl = 'api/v1/applications/documents/';
export const applicationByContactUrl = 'api/v1/applications/contact-applications/';
export const applicationByTrustUrl = 'api/v1/applications/trust-applications/';
export const applicationEndPoint = 'api/v1/applications/family-applications/';
export const applicationdocEndPoint = 'api/v1/applications/documents/';

/* --------------------- AUTH NET --------------------- */
export const authnetUrl = '/api/v1/authorize-net/authnet-payment-profile/';
export const card = 'api/v1/authorize-net/authnet-payment/';
export const paymentProfile = 'api/auth-net/payment-profile/';

/* --------------------- CHATTER --------------------- */
export const chatterUrl = 'api/chatter/chatter/';
export const trustChatterUrl = 'api/chatter/';
export const chatterUrlV1 = 'api/v1/chatter/chatter/';

/* --------------------- CONTACT --------------------- */
// old
export const contactShort = 'api/contact/contact-short/';
export const contactMassEdit = '/api/contact/contact-mass-update/';
export const contactMassDelete = 'api/contact/contact-mass-delete/';
export const pistatusUrl = 'api/contact/pi-status/';
export const subcontactUrl = 'api/contact/subcontact/';
export const subcontactDetailUrl = 'api/contact/subcontact-detail/';
export const subcontactFormUrl = 'api/contact/subcontact-form/';
export const contactUrl = 'api/contact/contact/';
export const contactHistory = 'api/contact/contact-history/';
export const tagsUrl = 'api/contact/tags/';
export const leadUrl = 'api/contact/lead/';
export const familyUrl = 'api/contact/family/';
export const familyShort = 'api/contact/family-short/';
export const walletUrl = 'api/contact/wallet/';
export const hbcUrl = 'api/contact/hbc/';
export const investorUrl = 'api/contact/investor/';
export const investorUrlShort = 'api/contact/investor-short/';
export const contactDocumentsUrl = 'api/contact/document/';
export const contactImagesUrl = 'api/contact/image/';
export const contactChatterUrl = 'api/contact/chatter/';
export const contactKanbanUrl = 'api/contact/contact-kanban/';
export const contactDetail = 'api/contact/contact-detail/';
export const massAmbassadorDel = 'api/contact/mass-ambassador-delete/';
export const familyLeadUrl = 'api/contact/family-lead/';
export const familyLeadStageUrl = 'api/contact/family-lead-stage/';
export const ambassadorLeadStageUrl = 'api/contact/ambassador-stage/';
export const investorLeadUrl = 'api/contact/investor-lead/';
export const investorLeadStageUrl = 'api/contact/investor-lead-stage/';
export const contactVendor = 'api/contact/vendor/';
export const ambassadorUrl = 'api/contact/ambassador/';

// v1
export const contactV1 = 'api/v1/contacts/contacts/'
export const subcontactUrlV1 = 'api/v1/contacts/subcontacts/'
export const paymentProfilev1 = 'api/v1/contacts/contact-payments/';
export const subContactApi = 'api/v1/contacts/mass-subcontacts/';
export const familyUrlv1 = 'api/v1/contacts/families/';
export const investorUrlv1 = 'api/v1/contacts/investors/';
export const contactForm = 'api/v1/contacts/contacts/';
export const familyLeadUrlv1 = 'api/v1/contacts/family-leads/';
export const familyLeadImportv1 = 'api/v1/contacts/family-lead-upload/';
export const investorLeadUrlv1 = 'api/v1/contacts/investor-leads/';
export const vendorsUrlV1 = 'api/v1/contacts/vendors/';
export const ambassadorUrlv1 = 'api/v1/contacts/ambassadors/';

/* --------------------- CORRESPONDENCE --------------------- */
export const emailTemplateUrl = '/api/v1/correspondences/correspondence-templates/';
export const emailsES = 'api/correspondence/gmail-record/';
export const smsES = 'api/correspondence/sms-record/';
export const sendCom = 'api/correspondence/send-coms/';

export const comsEmailsV1 = "/api/v1/correspondences/correspondence-instance-gmail/";
export const comsSmsV1 = "/api/v1/correspondences/correspondence-instance-sms/";
export const comsSnailV1 = "/api/v1/correspondences/correspondence-instance-snail/";
export const comsJobsV1 = "/api/v1/correspondences/correspondence-jobs/";
export const comsTemplatesV1 = "/api/v1/correspondences/correspondence-templates/";
export const comsPhoneNumsV1 = "/api/v1/correspondences/phone-numbers/";

/* --------------------- DOCUMENT --------------------- */
export const documentsUrl = 'api/v1/documents/documents/';
export const documentsTypeUrl = 'api/v1/documents/document-types/';

/* --------------------- IMAGES --------------------- */
export const imagesUrl = 'api/image/images/';
export const imagesUrlV1 = "api/v1/images/images/";

/* --------------------- INVESTOR --------------------- */
export const investorWalletUrlV1 = 'api/v1/portals/wallet/';
export const investmentDetailUrlV1 = 'api/v1/portals/investment-details/';

/* --------------------- LOAN --------------------- */
// old
export const loansUrl = 'api/loans/loans/';
export const loansShortUrl = '/api/loans/loan-short/';
export const loansDetailUrl = '/api/loans/loan-detail/';

// v1
export const loansUrlv1 = 'api/v1/loans/loans/';
export const eviction = 'api/v1/loans/eviction-stages/';
export const autopayUrlV1 = 'api/v1/loans/loan-autopay/';
export const loanTransactionsUrlV1 = 'api/v1/loans/loan-ledger/'
export const loanOverridesUrlV1 = 'api/v1/loans/loan-overrides/'
export const massLoanOverridesUrlV1 = 'api/v1/loans/mass-overrides/'
export const loanLedgerUrl = '/api/v1/loans/loan-ledger/';
export const loanFuturePaymentsUrlV1 = '/api/v1/loans/future-payments/';
export const resetTransactionsUrlV1 = '/api/v1/loans/reset-transactions/';
export const loanEscrowUrlV1 = '/api/v1/loans/loan-escrow/';

/* --------------------- PORTALS --------------------- */
export const employeeDashboardUrl = '/api/v1/admin/admin-dashboard/';
export const dashboardReturnDataUrl = '/api/v1/admin/investor-data/';
export const incomeTableUrl = '/api/v1/admin/income-table/';
export const investorPortalUrl = 'api/v1/portals/investor-portal/';
export const investorYearlyUrl = 'api/v1/portals/yearly-data/';
export const investorMarketingUrl = 'api/v1/portals/marketing/';

/* --------------------- TEMPLATES --------------------- */
export const emailTemplateVariablesNameUrl = '/api/v1/doc-templates/available-models/';
export const mailMergeModelsV1 = "/api/v1/doc-templates/available-models/";
export const docTemplatessV1 = "/api/v1/doc-templates/templates/";
export const docGenerateV1 = "/api/v1/doc-templates/generate/";
export const sendTemplateDataUrl = '/api/templates/mass-instance/';
export const sentMessageUrl = '/api/templates/mass-coms/';

/* --------------------- TICKETS --------------------- */
export const userRequestUrl = "/api/v1/tickets/user-requests/";
export const hrReportsUrl = "/api/v1/tickets/hr-reports/";
export const itTicketsUrl = "/api/v1/tickets/it-tickets/";
export const softwareRequestsUrl = "/api/v1/tickets/software-requests/";
export const qualAlertsUrl = "/api/v1/tickets/qual-reports/";

/* --------------------- TRUST --------------------- */
// old
export const trustSituationUrl = 'api/trust/situations/';
export const trustSituationTagsUrl = 'api/trust/situation-tags/';
export const trustKanbanUrl = 'api/trust/trust-kanban/';
export const trustDetailUrl = 'api/trust/trust-detail/';
export const trustFormUrl = 'api/trust/trust-form/';
export const trustTabUrl = 'api/trust/trust-tabs/';
export const trustImportUrl = 'api/trust/trust-import/';
export const statusUrl = 'api/trust/status/';
export const setUpUrl = 'api/trust/set-up/';
export const furtherRepairUrl = 'api/trust/further-repair/';
export const trustUrl = 'api/trust/trust/';
export const trustUrlShort = 'api/trust/trust-short/';
export const ownershipUrl = 'api/trust/ownership/';
export const familyHistoryUrl = 'api/trust/family-history/';

// v1
export const publicUrl = '/api/v1/trusts/on-market/';
export const marketingHistoryUrl = 'api/v1/trusts/marketing-history/';
export const marketingReportUrl = 'api/v1/trusts/marketing-reports/';
export const ownershipUrlV1 = 'api/v1/trusts/ownership/';
export const trustUrlv1 = 'api/v1/trusts/trusts/';
export const furtherRepairUrlV1 = 'api/v1/trusts/repair-stages/';
export const setupUrlV1 = 'api/v1/trusts/setup-stages/';
export const trustRtpUrlv1 = 'api/v1/trusts/trusts-rtp/';
export const trustSituationUrlv1 = 'api/v1/trusts/situations/';
// export const trustRtpUrl = 'api/v1/trusts/trusts/';

/* --------------------- MAPS --------------------- */
export const acquisitionsMapUrl = 'api/v1/acquisitions/acquisition-map/'
export const contactsMapUrl = 'api/v1/contacts/contact-map/'
export const loansMapUrl = 'api/v1/loans/loan-map/'
export const trustsMapUrl = 'api/v1/trusts/trusts-map/'

/* --------------------- DEP --------------------- */
export const billsUrl = 'api/accounting/bills/';
export const todosUrl = '/api/todo/todo/';
